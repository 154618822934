.dicom-viewer {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100% - 64px); /* the navbar is 64px */
}

.dicom-viewer .download-progress {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    gap: 1rem;

    font-weight: bold;
    font-size: 1.2em;
}

.dicom-viewer .frame-display {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.dicom-viewer .frame-display img {
    height: 90%;
    max-width: 90%;
    min-height: 320px;
}

.dicom-viewer .frame-controls {
    min-width: 90%;
    display: inline-flex;
    justify-content: center;
    gap: 1rem;
}

.dicom-viewer input[type="range"] {
    min-width: 50%;
}

.dicom-viewer .error {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;

    font-size: 1.2em;
    color: #e32d2d;
    text-align: center;
}
